  (($) ->
    $ ->
      $header = $('#header')
      $content = $('#content')
      $menuHeight = $('#header').height()
      $startPos = 0
      # Nav Fixed
      $(window).scroll ->
        $currentPos = $(this).scrollTop()
        if $currentPos > $startPos
          if $(window).scrollTop() >= 50
            $('#header').css 'top', '-' + $menuHeight + 'px'
        else
          $('#header').css 'top', 0 + 'px'
        $startPos = $currentPos
  
        if $(window).scrollTop() > $menuHeight
          $header.addClass 'fixed'
        else
          $header.removeClass 'fixed'
        return
  
      scrollTop = undefined
      # Nav Toggle Button
      $('#nav-toggle').on 'click', ->
        $('#gnav').slideToggle 300
  
      $('#nav-toggle').click ->
        $header.toggleClass 'open'
        $content.toggleClass 'open'
        $('body').toggleClass 'hidden'
        return
      return
    return
  ) jQuery
  
  (($) ->
    $target = $('.widget_categories_origin').find('.cat-list').children('.cat-item')
    $button = $target.children('.count')
    $children = $target.children('.children')
    $children.each ->
      $(this).addClass('is-active').slideUp 0
    $button.on 'click', ->
      $(this).toggleClass (index, className) ->
        if className.indexOf('is-open') == -1
          $(this).removeClass 'is-close'
          'is-open'
        else
          $(this).removeClass 'is-open'
          'is-close'
      $(this).siblings('.children').stop(true, true).slideToggle()
  ) jQuery
  
  (($) ->
    scrollTop = 0
    $('.post').find('.author-name-pc').on('click',
      (e)->
        console.log 'click'
        e.stopPropagation()
        scrollTop = $(window).scrollTop()
        console.log scrollTop
        $('body').addClass('noscroll').css('top', (-scrollTop)+'px')
        $('.balloon').addClass('is-active')
    )
  
    $('.balloon').find('.close').on('click',
      (e)->
        e.stopPropagation()
        console.log 'close'
        $('.balloon').addClass('is-animated')
        $('.balloon').on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend',
          ()->
            if $(this).hasClass('is-active') and $(this).hasClass('is-animated')
              $('body').removeClass('noscroll').removeAttr('style')
              console.log scrollTop
              $(window).scrollTop(scrollTop)
              $(this).removeClass('is-active').removeClass('is-animated')
        )
    )
  ) jQuery
  
  (($) ->
    $('.author-name-sp, .author-info').on('touchstart', (e)->
      e.stopPropagation()
    )
  
    $('.author-name-sp').on('touchstart', (e)->
      if $('.author-info').hasClass('is-active')
        $('.author-info').addClass('is-animated')
        $('.author-info').on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend',
          ()->
            if $(this).hasClass('is-active') and $(this).hasClass('is-animated')
              $(this).removeClass('is-animated')
              $(this).removeClass('is-active')
        )
      else
        $('.author-info').addClass('is-active')
    )
  
    $('#content').on('touchstart', (e)->
      if $('.author-info').hasClass('is-active')
        $('.author-info').addClass('is-animated')
        $('.author-info').on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend',
          ()->
            if $(this).hasClass('is-active') and $(this).hasClass('is-animated')
              $(this).removeClass('is-animated')
              $(this).removeClass('is-active')
        )
    )
  ) jQuery
  
  (($) ->
    $footerSlide = $('.f-slide')
    $footerSlide.slidesjs
      width: 294
      height: 210
      navigation: active: false
      play:
        effect: 'fade'
        auto: true
      pagination: active: false
      callback: start: (number) ->
        $footerSlide.parent('#f-slide').addClass 'is-active'
  ) jQuery
  
  (($) ->
    $id = $('body').attr('id');
    $class = $('body').attr('class');
    if $class.indexOf('page-id-172') != -1 or $class.indexOf('page-id-175') != -1
      $form = $('form[id$=\'_form\']')
      if $form != null
        $form.off()
        $form.unbind()
        $('div[id*=err_]').remove()
        $('#message').remove()
        if typeof common_validation != 'undefined' and common_validation != null
          common_validation.initializeForm $form, $id, 'message', '.order', 'after'
          return common_validation.controlPage($id);
  ) jQuery
  